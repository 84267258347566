
import { Component, Prop, Provide, Vue, Watch } from "vue-property-decorator";
import { GetByPageEvaluateApi } from "@/network/modules/mine";
import { GetByPageEvaluateReq,GetByPageEvaluateReqTw } from "@/interface/res/mine";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

@Component({
  components: {
    ElImageViewer
  },
  computed: {}
})
export default class CommentList extends Vue {
  @Prop({ type: String }) iCode!: string;
  @Prop({ type: String }) commodityId!: number;

  @Prop({ type: String }) getType!: string;

  @Prop({ type: Number }) readonly withPicture!: number;

  @Provide() commodity: GetByPageEvaluateReqTw = {
    commodityId: '',
    pageNum: 1,
    pageSize: 6,
    withPicture: 0
  };
  @Provide() condition: GetByPageEvaluateReq = {
    galleryId: "",
    pageNum: 1,
    pageSize: 6,
    withPicture: 0
  };
  @Provide() listTotal: number = -1;
  @Provide() list: Array<any> = [];
  @Provide() loading: boolean = false;

  @Provide() showBig: boolean = false;
  @Provide() showBigImgList: Array<any> = [];
  @Provide() showBigImgListIndex: number = 0;

  @Watch("withPicture", { immediate: true, deep: false })
  private iValChange(): void {
    const _this: any = this;
    _this.condition.pageNum = 1;
    _this.condition.galleryId = _this.iCode;
    _this.commodity.commodityId = _this.commodityId;
    _this.condition.withPicture = _this.withPicture;
    _this.GetByPageEvaluateFn();
  }

  @Watch("showBig", { immediate: false, deep: false })
  private showBigChange(newVal: boolean): void {
    const _this: any = this;
    const mo = function(e: any) {
      e.preventDefault();
    };
    if (newVal) {
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    } else {
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    }
  }

  mounted() {
    const _this: any = this;
  }

  /**
   * @Author HS
   * @Date 2021/7/29 11:19 上午
   * @Description: 图片放大
   * @Params: { object ：item - true } [当前数据]
   * @Params: { number ：index - true } [下标]
   * @Return: null
   */
  imgClick(item: any, index: number) {
    const _this: any = this;
    _this.showBigImgList = item;
    _this.showBigImgListIndex = index;
    _this.showBig = true;
  }

  /**
   * @Author HS
   * @Date 2021/8/23 7:37 下午
   * @Description: 评论列表
   * @Params: null
   * @Return: null
   */
  async GetByPageEvaluateFn() {
    const _this: any = this;
    if (_this.condition.pageNum == 1) {
      _this.listTotal = -1;
      _this.list = [];
    }
    _this.loading = true;
    console.log(
      "%c [  22---]: ",
      "color: #bf2c9f; background: pink; font-size: 13px;",
      _this.getType
    );
    let params:any= {};
    if (_this.getType == "1") {
      params= { ..._this.condition };
    } else {
      params= { ..._this.commodity };
    }

    console.log(params);
    const res = await GetByPageEvaluateApi(params);
    let a = setTimeout(() => {
      _this.loading = false;
      clearTimeout(a);
    }, 500);
    const { data, code, total, msg } = res;
    if (code != 200) {
      _this.$message.error(msg);
      return;
    }
    data.map((item: any) => {
      item.evaluatorUrlsArr = JSON.parse(item.evaluatorUrls);
    });
    _this.list = [..._this.list, ...data];
    _this.listTotal = total;
  }
}
